.square {
  background: #fff;
  color: black;
  border: 1px solid #999;
  float: left;
  /* font-size: 24px; */
  font-weight: bold;
  /* line-height: 34px; */
  margin: -1px;
  padding: 0;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 0px;
}

.square:hover {background: #e7e7e7; }

.board {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 39%;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}
