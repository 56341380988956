/* shared with all modals */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
}
.modalWrapper {
  position: fixed;
  /* top: 4rem;
  right: 5rem; */
  z-index: 1050;
  /* width: 10%;
  height: 10%; */
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modalClose{
  float: right;
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}
.modalClose:hover, .modalClose:focus{
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* User Nav Modal */
.userNavModalHeader {
  float: right;
  padding: 0px;
  padding-right: 10px;
  border-bottom: 0;
}
.userNavModalContent{
  background-color: #fefefe;
  overflow: hidden;
  margin: auto;
  border-radius: 5px;
  border: 1px solid #888;
  width: 150px;
  position: fixed;
  right: 10px;
  top: 60px;
}
.userNavModalContent a {
  float: left;
  display: block;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
}
.userNavModalContent a:hover{
  background-color: lightgrey;
}

/* Filter Modal */
.filterModalHeader {
  text-align: center;
  background-color: #f6f6f6;
  position: fixed;
  width: 441px;
  border-bottom: 0.5px solid lightgrey;
  z-index: 1;
}

.filterModalBody{
  background-color: #f6f6f6;
  margin: auto;
  border-radius: 5px;
  border: 1px solid #888;
  width: 500px;
  max-height: 100vh;
  position: fixed;
  left: calc(50% - 250px);
  top: 0%;
  overflow-y: auto;

  /* padding-top: 5px; */
  padding-right:20px;
  padding-left:20px;
  padding-bottom: 15px;
  z-index: 1050;
  }

.filterModalContent{
  /* background-color: lightgreen; */
  padding-top: 55px;
}
/* Module Modal */
.moduleModalContent{
  overflow: hidden;
  margin: auto;
  /* width: 500px; */
  max-height: 90%;
  top: 10%;
  overflow-y: auto;
  }

@media screen and (max-width: 600px) {
  .filterModalHeader,
  .filterModalBody,
  .moduleModalContent {
    left: 0%;
    width: 100%;
    
    /* top: 0; */
  }
  .filterModalBody{
    border-radius: 0px;
    border: 0px;
  }

  .filterModalHeader{
    padding-right: 15px;

  }
  .modalOverlay{
    width: 100%;
    height: 100%;
  }

}