.toggleSwitchCheckbox  {
  height: 0px;
  width: 0px;
  visibility: hidden;
  position: absolute;
}


.toggleSwitchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.toggleSwitchLabel .toggleSwitchButton {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 22px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.toggleSwitchText{
  font-weight: normal;
  color: white;
  display: none;
}

.toggleSwitchCheckbox:checked + .toggleSwitchLabel .toggleSwitchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggleSwitchLabel:active .toggleSwitchButton {
  width: 30px;
}