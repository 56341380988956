.gridContainer {
  margin-top: 75px;
  display: grid;
  gap: 10px;
  padding: 10px;
  justify-content: center;
}
/* .gridContainer > div{ */
.gridItem {
  position: relative;
  background-color: #4d81ab;
  border: 1px solid black;
  color: white;
  text-align: center;
  cursor: pointer;
}

/* .gridContainer > div:hover{ */
.gridItem:hover{
  background-color: #4d81ab69;
  color: black;
}

.gridContainer .disabled {
  background-color: lightgrey;
  cursor: auto ;
}
.squareIcon {
  font-size:40px;
}
.squareHelpTextContainer .squareHelpText{
  visibility: hidden;
  width: 120px;
  background-color: rgb(140, 140, 140);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 15px;
  top: 35px;
  right: 5px;

  position: absolute;
  z-index: 1;
}
.squareHelpTextIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 25px;
}

.squareHelpTextContainer:hover .squareHelpText {
  visibility: visible;
}


.squareFont{
  font-size: 25px;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {

  .gridContainer {
    grid-template-columns: calc(100% /3.1) calc(100% /3.1) calc(100% /3.1);
  }

  .gridContainer > div {
    font-size: 30px;
    padding: 15%;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {

  .gridContainer {
    grid-template-columns: calc(100% /2.2) calc(100% /2.2);
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 0%;
  }

  .gridContainer > div {
    font-size: 5vw;
    padding: 15%;
  }
}