.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  width: auto;
  height: 200px;
  background-color: pink;
  padding: 20px;
  border: 1px solid black;
  box-shadow: 10px 10px 5px #888888;
}