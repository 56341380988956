.sectionContents{
  display: flex;
  /* background-color: red; */
  flex-direction: column;
}

.sectionContents > .sectionContentsItem {
  /* background-color: orange ; */
  /* margin: 10px; */
  padding: 10px;
  display: flex;
  
  /* font-size: 30px; */
  
}

.sectionContentsLeft {
  /* background-color: yellow; */
  /* display: flex; */
  min-width: 200px;
  max-width: 200px;
  display: flex;
}

.sectionContentsRight{
  /* background-color: green; */
  display: flex;
  flex-direction: row;
}

.sectionContentsDetailsOption{
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  
}

