/* Only global if imported*/

/* * {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  background-color: #ffffff;
  margin: 0px;
} */
h1 {
  color: #4169e1;
  margin-top: 0px;
  margin-bottom: 10px;
}
h2 {
  color: #4169e1;
  margin-top: 0px;
  margin-bottom: 10px;
}
P {
  color: black;
}
.center {
  text-align: center;
}
.react-icons {
  vertical-align: text-top;
}

a:link {
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  /* text-decoration: none; */
  color: #0000EE;
}

a:hover {
  font-weight: 500;
  /* color: green; */
}

.white,
.white:visited {
  color: white;
}

.white:hover{
  color: #5bc0de;
}

.mmcylogo { 
  height: 50px;
}
.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.section {
  /* background-color: rgb(231, 231, 231); */
  margin-top: 75px;
  margin-bottom: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%
}

.fixedcontainer {
  padding: 25px;
  width: 450px;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  overflow-y: auto;
}

.responsivecontainer {
  padding: 25px;
  min-width: 450px;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/* @media screen and (max-width: 2000px) {
  .sectionA {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .sectionA {
    width: 70%;
  }
}

@media screen and (max-width: 700px) {
  .sectiongrey {
    padding: 25px;
  }
  .sectionA {
    padding: 25px;
    width: 80%;
  }
} */

@media screen and (max-width: 600px) {
  .section {
    padding: 5px;
    margin-top: 50px;
  }
  .fixedcontainer, .responsivecontainer {
    padding: 5px;
    width: 100%;
    min-width: auto;
    box-shadow: 0 0px 0px 0;

  }
  .hideOnMobile {
    display: none;
  }
  
}