form {
  padding-top: 10px;
}
.center {
  text-align: center;
  margin: auto;
}

label {
  font-weight: bold;
}

.input {
  margin-top: 0.2rem;
}

.searchBox {
  width: 300px;
  padding: 0.3rem;
  padding-left: 25px;
}

.input select,
.input input,
.input textarea {
  font: inherit;
  color: #17375E;
  border-radius: 4px;
  padding: 0.25rem;
  background-color: white;
  border: 1px solid rgb(206, 206, 206);
  text-align: left;
}
.input select
 {
  cursor: pointer;
}

.fieldGroupNone input,
.fieldGroupNone select,
.fieldGroupNone textarea
{
  width: 100%;
} 

.fullwidth input{
  width: 100%;
}

.fullwidth label {
  text-align: left;
  display: block;
}

.input textarea {
  resize: none;
}

/* Remove arrows from number fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=checkbox] {
  height: 1.3em;
  cursor: pointer;
}



.fieldGroupLeft {
  /* background-color: lightgreen; */
 display: inline-block;
 width: 49%;
 margin-right: 10px;
}

.fieldGroupMiddle {
  /* background-color: lightgreen; */
 display: inline-block;
 /* width: 30%; */
 margin-right: 10px;
}


.fieldGroupLeft input,
.fieldGroupLeft select,
.fieldGroupLeft p,
.fieldGroupMiddle input,
.fieldGroupMiddle select,
.fieldGroupMiddle p,
.fieldGroupRight input,
.fieldGroupRight select,
.fieldGroupRight p{
  float: left;
  width: 100%;
} 

.checkboxRow input{
  width: 50%;
}
/* .fieldGroupLeft input[type=number]{ */
  /* max-width: 95%; */
/* }  */

.fieldGroupRight {
  /* background-color: pink; */
  display: inline-block;
  /* max-width: 45%; */
  /* margin-left: 10px; */
  width: 47%;
}

.listOfSelectValuesValue {
  float: left;
  width: 84%;
}

.listOfSelectValuesFlag {
  display: inline-block;
  width: 8%;
  
}

.listOfSelectValuesDelete {
  display: inline-block;
  width: 8%;
}

/* .fieldGroupRight input[type=number]{ */
  /* width: 30%; */
/* }  */

/* .fieldGroupSize */

input:disabled,
select:disabled {
  background-color: rgba(211, 211, 211, 0.441);
  cursor: default;
}

button {
  background-color: #5bc0de;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  margin: 0.4rem;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2)
}

.navButton {
  font-size: 16px;
  padding: 1px 1px;
}

.navButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navButtonIcon{
  padding: 2px 2px 2px 2px;
  font-size: '30px',
}

.navButtonText {
  padding: 9px 10px 9px 1px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.smallButton {
  font-size: 10px;
  padding: 2px 5px;
  margin: 0.2rem;
}

.mediumButton {
  font-size: 14px;
  padding: 5px 7px;
  margin: 0.2rem;
}

.backButton {
  float: left;
  /* font-size: 14px; */
  /* padding: 5px 7px; */
  margin: 0rem;
}

.addButton, .editButton {
  float: right;
  /* font-size: 14px; */
  /* padding: 5px 7px; */
  margin: 0rem;
}

button:hover {
  background-color: #31b0d5;
  cursor: pointer;
}

.red {
  background-color: #d9534f;
  color: white
}

.red:hover {
  background-color: #c9302c;
}

.orange {
  background-color: #f0ad4e;
  color: white
}

.orange:hover {
  background-color: #ec971f;
}

.green {
  background-color: #5cb85c;
  color: white
}

.green:hover {
  background-color: #449d44;
}

.lightblue {
  background-color: #5bc0de;
  color: white
}

.lightblue:hover {
  background-color: #31b0d5;
}

.darkblue {
  background-color: #4169e1;
  color: white
}

.darkblue:hover {
  background-color: #2355E1;
}

button:disabled,
button:disabled:hover {
  background-color: #acacac;
  color: #5f5f5f;
  cursor: not-allowed;
}

i {
  /* font-weight: bold; */
  color: #31b0d5;
  font-size: 16px;
  padding: 0px 5px;
  border: none;
  margin: 0.1rem;
  border-radius: 60px;
  cursor: pointer;
  display: inline-block;
}

i:hover {
  background-color: whitesmoke;
}

.error {
  /* margin-top: 0.25rem; */
  color: #d8182c;
  font-weight: normal;
}

.errormessage {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.5rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.fieldErrorMessage {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* margin: 1rem 0; */
}

/* https://getbootstrap.com/docs/4.0/components/alerts/ */
.successmessage {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 0.5rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.warningmessage {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 0.5rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.selectOptions {
  display: block;
  /* cursor: pointer; */
}

.selectOption {
  display: flex;
  color: black;
  font-weight: normal;
  /* cursor: pointer; */
}

.selectOption:hover {
  background-color: #e7e5e5
}

@media screen and (max-width: 600px) {
  .navButton {
    border-radius: 18px;
  }
  .navButtonText{
    display: none;
  }

  i {
    font-size: 24px;
    /* padding: 1px 1px; */
  }
  
}