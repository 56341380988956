.table {
  /* margin-top: 5px; */
  border-collapse: collapse;
  width: 100%;
  /* white-space: nowrap; */
}

.table th {
  text-align: left;
  background-color: var(--darkBlue);
  position: sticky;
  z-index: 3;
  /* top: 0; */
  top: 49px;
  border: 1px solid white;
  /* padding: 0.5rem; */
  font-weight: normal;
  color: white;
  cursor: pointer;
}

.table th:hover,
.table .trhover:hover {
  background-color: var(--tableHover);
}

.clickableHeader {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.notClickableHeader {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  cursor: default;

}

.container {
  display: grid;
}

.headerLeft {
  /* background: #ff9e2c; */
  grid-column: 1;
}

.headerRight {
  /* background: #b6701e; */
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: 5px;
}

.divA{
  grid-column: 1;
  margin-left: 9px;
}
.divB{
  grid-column: 2;
}
.divC{
  grid-column: 3;
  margin-right: 9px;
}
.menuicon {
  height: 18px;
  /* margin-left: 10px;
  position: absolute;
  top: 10px;
  right: 10px; */
}

.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px 8px;
}

.table td a:link,
.table td a:visited {
  text-decoration: none;
  color: black;
}

.table tr:nth-child(even) {
  background-color: #d4d4d460;
}


/* thead span.ascending::after {
  content: '⬇️';
  display: inline-block;
  margin-left: 1em;
}

thead span.descending::after {
  content: '⬆️';
  display: inline-block;
  margin-left: 1em;
} */

.colDisP0 {
  display: none;
}

@media screen and (max-width: 1500px) and (min-width: 1225px) {
  .colDisP6 {
    display: none;
  }
}

@media screen and (max-width: 1225px) and (min-width: 1045px) {
  .colDisP6 {
    display: none;
  }

  .colDisP5 {
    display: none;
  }
}

@media screen and (max-width: 1045px) and (min-width: 700px) {
  .colDisP6 {
    display: none;
  }

  .colDisP5 {
    display: none;
  }

  .colDisP4 {
    display: none;
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  .colDisP6 {
    display: none;
  }

  .colDisP5 {
    display: none;
  }

  .colDisP4 {
    display: none;
  }

  .colDisP3 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .colDisP6 {
    display: none;
  }

  .colDisP5 {
    display: none;
  }

  .colDisP4 {
    display: none;
  }

  .colDisP3 {
    display: none;
  }

  .colDisP2 {
    display: none;
  }

}

/* Table Menu Dropdown */
.dropdownTableMenu {
  position: relative;
  /* display: inline-block; */
}

.dropdownButton {
  position: relative;
  display: inline-block;
}


.dropdowncontent {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  overflow: auto;

}

th .dropdowncontent {
  top: 1px;
  cursor: default;
  /* left: -8px; */
}

.dropdowncontent a,
.dropdowncontent p,
.dropdowncontent input,
.dropdowncontent option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
}

.dropdowncontent select,
.dropdowncontent option {
  width: 100%;
  border: none;
  overflow: hidden;
}

.dropdowncontent a:hover,
/* .dropdowncontent input:hover, */
.dropdowncontent option:hover {
  background-color: #e7e5e5
}

/* used for showing menu on hover */
/* th:hover .dropdowncontent,
.show .dropdowncontent {
  display: block;
} */

.dropdowncontent .warning {
  color: red;
}

.divider {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.show {
  display: block;
}

.selectOptions {
  display: block;
}

.table label {
  color: black;
  font-weight: 600;
  
  padding: 5px;
  margin-left: 5%;
  margin-right: 5%;
}

.table input{
  color: black;
  padding: 5px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
}
.table select{
  color: black;
  font-weight: 600;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /* background-color: #f9f9f9; */
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
}

.table input[type=checkbox] {
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

.checkboxRow {
  float: left;
}

.filterModalFields select,
.filterModalFields input{
  color: black;
  padding: 5px;
  margin-left: 0%;
  width: 100%;
  /* margin-right: 3%; */
  font-size: 14px;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  background-color: white;
}

.filterModalNumber select,
.filterModalNumber input,
.filterModalTime select,
.filterModalTime input {
  width: 48%;
  margin-right: 2%;
}

.contentsCenter td {
  text-align: center;
}

.contentsCenter th {
  text-align: center;
}