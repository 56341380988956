.table {
  border: 1px solid black;
  border-collapse: collapse;
  margin: auto;
}

.tr {
  /* background-color: cadetblue; */
  border-bottom: 1px solid black;
}

.th {
  background-color: var(--darkBlue);
  color: white;
  text-align: center;
  padding: 5px 0;
  font-weight: 700;
}

.day {
  min-width: 100px;
  height: 100px;
  vertical-align: top;
  text-align: center;
  padding: 0px;
  /* background-color:rgba(144, 238, 144, 0.178); */
  /* border-radius: 30px; */
  cursor: pointer;
  border-right: 1px solid black;
}

.day:hover {
  background-color: var(--tableHover);
}

.item {
  background-color: lightgrey;
  border-radius: 10px;
  margin: 2px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.item:hover {
  background-color: darkgray;
}

.button {
  background-color: lightblue;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.button:hover {
  background-color: darkcyan;
}

select {
  padding: 5px;
  background-color: rgb(245, 245, 245);
  border: 0px;
  /* margin: 5px; */
  font-size: 40px;
  cursor: pointer;
}

.selectOptions {
  font-size: 15px;
  /* background-color: yellow; */
}
@media screen and (max-width: 600px) {
  .todayButton{
    padding: 6px;
  }
}

@media screen and (max-width: 750px) {
  th {
    font-size: 15px;
  }

  .day {
    min-width: 50px;
    height: 50px;
  }


  select {
    font-size: 20px;
  }

  .item {
    font-size: 10px;
    margin: 0px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .button {
    border-radius: 18px;
  }

  .buttonText {
    display: none;
  }
}

