.horizontalRadioSwitchContainer {
  /* margin-top: 5px; */
  display: inline-block;
  /* border: 2px solid green; */
  border-radius: 5px;
  /* z-index: 2;  */
  cursor: pointer;
  margin-bottom: 5px;
}

.horizontalRadioSwitchItem {
  padding: 10px; 
  /* width: 18px; */
  /* height: 18px; */
  /* min-width: 18px; */
  /* min-height: 18px; */
  display: inline-block;
  background-color: lightgrey;
  /* border-radius: 50%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-right: 12px; */
  /* transition: all 0.1s linear; */
  /* display: inline-block */
  /* cursor: pointer; */
  transition: all 0.27s linear;
  /* border-radius: 5px;  */
  /* z-index: 4;  */
  cursor: pointer;
}


.horizontalRadioSwitchItem:hover { 
  background-color: #b4edde;

}
.horizontalRadioSwitchItemSelected {
  /* border: 2px solid #666; */
  background-color: #06D6A0;
  color: white;
}
.horizontalRadioSwitchItemSelected:hover {
  /* border: 2px solid #666; */
  background-color: #07eaad;
  color: white;
}


.horizontalRadioSwitchLabel{
  /* padding-right: 8px; */
  font-weight: normal;
  cursor: pointer;
}