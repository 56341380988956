/* Truely global settings */

:root {
  --darkBlue: rgb(23, 55, 94);
  --tableHover: #a2d4c4;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  background-color: var(--white);
  margin: 0px;
}

button {
  background-color: #5bc0de;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  margin: 0.4rem;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2)
}

button:hover {
  background-color: #31b0d5;
  cursor: pointer;
}

.button {
  font-size: 16px;
  padding: 1px 1px;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonIcon{
  padding: 2px 2px 2px 2px;
  /* font-size: 30px; */
}

.buttonText {
  padding: 9px 10px 9px 1px;
}

.buttonTextRight {
  padding: 9px 1px 9px 10px;
}

.backButton {
  float: left;
  margin: 0rem;
}

.addEditButtons {
  float: right;
  margin: 0rem;
}

.red {
  background-color: #d9534f;
  color: white
}

.red:hover {
  background-color: #c9302c;
}

.orange {
  background-color: #f0ad4e;
  color: white
}

.orange:hover {
  background-color: #ec971f;
}

.green {
  background-color: #5cb85c;
  color: white
}

.green:hover {
  background-color: #449d44;
}

.lightblue {
  background-color: #5bc0de;
  color: white
}

.lightblue:hover {
  background-color: #31b0d5;
}

.darkblue {
  background-color: #4169e1;
  color: white
}

.darkblue:hover {
  background-color: #2355E1;
}



@media screen and (max-width: 600px) {
  .button {
    border-radius: 18px;
  }

  .buttonText,
  .buttonTextRight,
  .hideOnMobile {
    display: none;
  }  
}