.autocompleteInput {
  border: 1px solid #999;
  padding: 8px;
  width: 100%;
}
.noAutocomplete {
  color: #999;
  padding: 8px;
}
.autocompleteInput{
  border: 1px solid #999;
  /* border-top-width: 0; */
  list-style: none;
  margin-top: 0;
  /* max-height: 143px; */
  overflow-y: auto;
  /* padding-left: 0; */
  width: 100%;
  position: relative;
}
.autocomplete {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  /* max-height: 143px; */
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: relative;
  color: black;
}
.autocomplete li {
  border: 1px solid #d4d4d4; 
  border-top: none;
  padding: 5px;
}
.autocomplete > .active {
  background-color: #94D1BE;
  cursor: pointer;
  font-weight: 600;
  color: white;
}

.autocomplete li:hover {
  background-color: #94d1be4f;
  cursor: pointer;    
}
.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}